import React from 'react'
import Layout from '../components/Layout'

const Timelapse = ({ location }) => {
  const pageTitle = 'Timelapse Calculator'
  const pageSlug = 'timelapse'
  return (
    <Layout location={location} pageSlug={pageSlug} pageTitle={pageTitle}>
      <div className="container page-header">
        <h1 className="page-header__title font-headline">{pageTitle}</h1>
      </div>

      <div className="intro container">
        <div className="lead">
          <p>This is timelapse calculator</p>
        </div>
      </div>
    </Layout>
  )
}

export default Timelapse
